import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as EmailValidator from 'email-validator';
import { phoneNumberRegEx } from '/src/functions/regEx'
import { formatOfficeLineDob } from '/src/functions/timeFormatter'
import submitLead from '/src/functions/submitLead'
import cleanedLeadsourceParam from '/src/functions/formUrlLeadsourceParamFilter'
import datasoapValidatePhone from '/src/functions/datasoapValidatePhone'

const CtaContainer = loadable(() => import('/src/components/CtaContainer'))
const InnerSection = loadable(() => import('/src/components/InnerSection'))
const RequiredText = loadable(() => import('/src/components/form-components/Required/RequiredText'))


const StepContactDetails = loadable(() => import('/src/forms/LeadGenJourney/StepContactDetails'))
const StepCover = loadable(() => import('/src/forms/LeadGenJourney/StepCover'))
const StepDob = loadable(() => import('/src/forms/LeadGenJourney/StepDob'))
const StepPartner = loadable(() => import('/src/forms/LeadGenJourney/StepPartner'))
const StepProtect = loadable(() => import('/src/forms/LeadGenJourney/StepProtect'))
const StepSex = loadable(() => import('/src/forms/LeadGenJourney/StepSex'))
const StepSmoker = loadable(() => import('/src/forms/LeadGenJourney/StepSmoker'))
const StepUserDetails = loadable(() => import('/src/forms/LeadGenJourney/StepUserDetails'))


const SubmitButton = loadable(() => import('/src/components/form-components/SubmitButton'))

const LeadGenJourney = ({ leadSource, successPage }) => {

    //const requiredArray = 'AB'
    //requiredArray.includes('A') ? console.log('YES A') : console.log('NO A')

    const [isSubmitted, setSubmitted] = useState(false)
    const [mdegFlag, setmdegFlag] = useState('')
    const [mdegValue, setmdegValue] = useState('')

    const validationSchema = Yup.object({
        //partner: Yup.string().required('This field is required'),
        //protect: Yup.string(),
        //smoker: Yup.string().required('This field is required'),
        coverAmount: Yup.string(),
        coverPeriod: Yup.string(),
        //dobDay: Yup.string().required('Day is required'),
        //dobMonth: Yup.string().required('Month is required'),
        //dobYear: Yup.string().required('Year is required'),
        //title: Yup.string(),
        firstname: Yup.string().required('First name is required'),
        //lastname: Yup.string().required('Last name is required'),
        //sex: Yup.string().required('This field is required'),
        //email: Yup.string().required('This field is required'),
        phone: Yup.string()
            .max(15, 'Cannot be longer than 15 digits')
            .matches(phoneNumberRegEx, 'Phone number is not valid')
            .required('Contact number is required')
            .test(
                'datasoap',
                'Phone number is not valid',
                async function (value) {
                    return datasoapValidatePhone(value)
                }
            ),
        postcode: Yup.string()
            .max(10, 'Cannot be longer than 10 digits')
            .required('Postcode is required')
    })

    const [utmCampaign, setUtmCampaign] = useState('')
    const [utmTerm, setUtmTerm] = useState('')
    const [subSubSource, setSubSubSource] = useState('')
    const [googleId, setGoogleId] = useState('')
    const [urlLeadsource, seturlLeadsource] = useState('')
    
    //const [msId, setMsId] = useState('')

    // Veriform params
    const [sourceId, setSourceId] = useState('')
    const [crCid, setCrCid] = useState('')

    useEffect(() => {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        setUtmCampaign(urlParams.get('utm_campaign'))
        setUtmTerm(urlParams.get('utm_term'))
        setSubSubSource(urlParams.get('sub_sub_source'))
        // Veriform
        setSourceId(urlParams.get('sourceID'))
        setCrCid(urlParams.get('cr_cid'))
        setGoogleId(urlParams.get('gclid'))

        if(urlParams.has('ls')){
            seturlLeadsource(cleanedLeadsourceParam(urlParams.get('ls')))
        } else {
            seturlLeadsource(leadSource || process.env.GATSBY_OFFICELINE_SOURCE)
        }

        if(urlParams.has('_ef_transaction_id')){
            setmdegFlag('Y')
        } else {
            setmdegFlag('N')
        }

        if(urlParams.has('_ef_transaction_id')){
            setmdegValue(urlParams.get('_ef_transaction_id'))
        } else {
            setmdegValue('N')
        }

        /*
        setTimeout(function () {
            setGoogleId(localStorage.getItem('cg_gclid'))
            //setMsId(localStorage.getItem('_uetmsclkid'))
        }, 1000)
        */
        //console.log("Original 1: "+leadSource)
    }, [])
    //console.log("Original 2: "+leadSource)
    //console.log(urlLeadsource)
    //console.log("EF Parampresent: "+mdegFlag)
    //console.log("EF Value Catptured: "+mdegValue)

    return (
        <Formik
            initialValues={{
                partner: 'NO',
                protect: 'unsure',
                smoker: 'NO',
                coverPeriod: '',
                coverAmount: '',
                dobDay: '',
                dobMonth: '',
                dobYear: '',
                title: '',
                firstname: '',
                lastname: '',
                sex: '',
                email: '',
                phone: '',
                postcode: ''
            }}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={async (values, actions) => {

                const emailChecked = EmailValidator.validate(values.email) ? values.email : '';      
                const emailValid = EmailValidator.validate(values.email) ? 'YES' : 'NO'; 

                const dobCheck = values.dobDay === 'Day' || values.dobMonth === 'Month' || values.dobYear === 'Year' || values.dobDay === '' || values.dobMonth === '' || values.dobYear === '' ? '1900-01-01' : formatOfficeLineDob(values.dobDay,values.dobMonth,values.dobYear);
                const dobValid = dobCheck === '1900-01-01' ? 'NO' : 'YES'; 



                const firebaseData = {
                    createdAt: new Date(),
                    source: urlLeadsource,
                    subSource: utmCampaign,
                    subSubSource: subSubSource,
                    googleId: googleId,
                    //msId: msId,
                    partner: values.partner,
                    protect: values.protect,
                    smoker: values.smoker,
                    coverPeriod: values.coverPeriod,
                    coverAmount: values.coverAmount,
                    dobDay: values.dobDay,
                    dobMonth: values.dobMonth,
                    dobYear: values.dobYear,
                    dobFull: dobCheck,
                    title: values.title,
                    firstname: values.firstname,
                    lastname: values.lastname,
                    sex: values.sex,
                    email: values.email,
                    phone: values.phone,
                    postcode: values.postcode,
                    mdeg: mdegFlag,
                    mdegValue: mdegValue,
                    emailValid: emailValid,
                    dobValid: dobValid,
                }

                const coverPeriod =
                    values.coverPeriod === `I don't know` || values.coverPeriod === `Please select`
                        ? 0
                        : Number(values.coverPeriod.slice(0, -6))
                const coverAmount =
                    values.coverAmount === `I don't know` || values.coverPeriod === `Please select`
                        ? 0
                        : Number(values.coverAmount.replace(/[^0-9-]+/g, ''))

                

                let olData = new URLSearchParams({
                    source: urlLeadsource,
                    cover_length: coverPeriod,
                    cover_amount: coverAmount,
                    app1_smoker: values.smoker,
                    app1_dob: dobCheck,
                    app1_first_name: values.firstname,
                    app1_last_name: values.lastname,
                    app1_gender: values.sex,
                    app1_email: emailChecked,
                    app1_telephone_1: values.phone,
                    app1_postcode: values.postcode,
                    // Goes to 'sub-source' field in OL
                    affiliate_id: utmCampaign,
                    utm_term: utmTerm,
                    // Goes to 'sub-sub-source' field in OL
                    sub_sub_source: subSubSource,
                    importer_data_3: googleId
                })

                if (values.partner === 'YES') {
                    olData.append('app2_first_name', 'Partner')
                    olData.append('app2_last_name', 'Partner')
                    olData.append('app2_dob', '1900-01-01')
                }

                if (
                    values.title !== 'Please select' ||
                    values.title !== 'Other'
                ) {
                    olData.append('app1_title', values.title)
                }

                const submitOptions = {
                    values: values,
                    actions: actions,
                    firebaseCollection: 'ctq',
                    firebaseData: firebaseData,
                    olData: olData,
                    veriform: {
                        sourceId: sourceId,
                        crCid: crCid
                    },
                    successPage: successPage || '/compare-success',
                    mdegValue: mdegValue,
                }

                setSubmitted(true)

                await submitLead(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting }) => (
                <Form>
                    <InnerSection>
                        <RequiredText />
                        <StepPartner 
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection variant="alt">
                        <StepProtect
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection>
                        <StepSmoker
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection variant="alt">
                        <StepCover
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection>
                        <StepDob
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection variant="alt">
                        <StepUserDetails
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection>
                        <StepSex
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection variant="alt">
                        <StepContactDetails
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </InnerSection>

                    <InnerSection>
                        <CtaContainer variant="centered">
                            <SubmitButton
                                initialText={'Compare Quotes'}
                                sentText={'Thank You!'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                            />
                        </CtaContainer>
                    </InnerSection>
                </Form>
            )}
        </Formik>
    )
}

LeadGenJourney.propTypes = {
    /**
     * Optional different lead source
     */
    leadSource: PropTypes.string,
    /**
     * Optional success page URL
     */
    successPage: PropTypes.string
}

export default LeadGenJourney
